<!--
 * @Author: cuihaopeng cuihaopeng@slodon.cn
 * @Date: 2023-06-09 14:25:31
 * @LastEditors: cuihaopeng cuihaopeng@slodon.cn
 * @LastEditTime: 2023-10-17 17:48:24
 * @FilePath: /pulizhenkong_pc/src/views/member/contract/agreement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <div class="sld_login">
        <div class="sld_login_header">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/`">
                    <img :src="configInfo.main_site_logo ? configInfo.main_site_logo : defaultImg" class="img" />
                </router-link>
            </div>
        </div>
        <div class="agreement_container">
            <h2 class="agreement_title">{{agreeContent.title}}</h2>
            <pre class="agreement_content" v-html="agreeContent.content"></pre>
        </div>
    </div>
</template>

<script>
    import { ref, getCurrentInstance, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex';

    export default {
        name: "FinaceContractAgreement",
        setup() {
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const store = useStore();
            const configInfo = ref(store.state.configInfo);
            const defaultImg = require('../../../assets/common_top_logo.png');
            const agreeContent = reactive({
                content: '',
                title: ''
            })
            const getInitData = () => {
                proxy.$get('v3/system/front/agreement/detail', {
                    agreementCode: 'digital_certificate_apply_agreement'
                }).then(res => {
                    if (res.state == 200) {
                        agreeContent.content = proxy.$quillEscapeToHtml(res.data.content);
                        agreeContent.title = res.data.title;
                    }
                })
            }

            onMounted(() => {
                getInitData()
            })

            return { L, agreeContent, configInfo, defaultImg }
        },
    };
</script>
<style lang="scss" scoped>
    @import '../../../style/agreement.scss';
    .agreement_container{
        width: 800px;
        
    }
    .agreement_content{
        font-size: 15px;
        line-height: 35px;
        white-space:normal;
        word-break:break-all;

        img {
            max-width: 100%;
        }
    }
</style>
<style lang="scss">
    .agreement_content{
        img {
            max-width: 100%;
        }
    }
</style>
